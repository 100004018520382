import type { Environment } from 'relay-runtime'

import { trackSignUpCompleted, trackWafGetTokenFailed } from 'lib/analyticsApi'
import { setAuthToken } from 'lib/cookieAuth'
import { getMessageFromUnknownError } from 'lib/getMessageFromUnknownError'
import type { AppsFlyerPBATrackingFn } from 'lib/tracking/appsFlyerPBAClient'
import { AF_COMPLETE_REGISTRATION } from 'lib/tracking/appsFlyerPBAClient'
import { FINANCEADS_SIGNUP } from 'lib/tracking/financeAds/consts'
import type { FinanceadsTrackingFn } from 'lib/tracking/financeAds/types'
import { SIGNUP } from 'lib/tracking/impact/consts'
import type { ImpactTrackingFn } from 'lib/tracking/impact/types'
import { checkUserIsNotBot } from 'lib/wafHelpers'
import signUpWithEmail from 'relay/mutations/signUpWithEmail'

interface SignupParams {
    environment: Environment
    impactTrackingFn: ImpactTrackingFn
    financeadsTrackingFn: FinanceadsTrackingFn
    appsFlyerPBATrackingFn: AppsFlyerPBATrackingFn
    sessionId?: string
    source?: string
    refPage?: string
    checkForCaptchaErrorAndTriggerFlow: (
        errorMessage: string,
        retryFn: () => Promise<void>,
    ) => boolean
    fbp?: string
    fbclid?: string
}

export interface SignupFormValues {
    email: string
    password: string
    gdpr: boolean
    firstName: string
    hasOptedInToMarketing: boolean | null
}

/**
 * Creates a function that handles the signup process.
 * @param params
 * @returns
 */
export const useSignup = (params: SignupParams) => {
    const handleSignup = async (
        values: SignupFormValues,
        setStatus: (status: { formError?: string }) => void,
        setSubmitting: (submitting: boolean) => void,
        onSuccess?: () => void,
    ) => {
        const { email, password, firstName, hasOptedInToMarketing = false } = values
        const gdprAccepted = values.gdpr

        if (!gdprAccepted) {
            throw new Error('Terms and conditions were not accepted')
        }

        try {
            const onFailBotCheck = () =>
                setStatus({ formError: 'Please wait a few seconds, then try again' })
            const didPassBotCheck = await checkUserIsNotBot(onFailBotCheck)

            if (!didPassBotCheck) {
                trackWafGetTokenFailed('SignUp')
                throw new Error('User is a bot')
            }

            const source = params.source

            const res = await signUpWithEmail(params.environment)({
                email,
                firstName: firstName || '',
                password,
                hasOptedInToMarketing,
                source,
                sessionId: params.sessionId,
            })

            setAuthToken(res!.signUpWithEmail!.accessToken!)
            const userId = res!.signUpWithEmail!.viewer.me!.id

            trackSignUpCompleted(
                'email',
                params.refPage || '',
                email,
                source,
                params.environment,
                params.fbclid || '',
                params.fbp || '',
            )
            params.impactTrackingFn(params.environment, SIGNUP, { userId })
            params.financeadsTrackingFn(params.environment, FINANCEADS_SIGNUP, null)
            params.appsFlyerPBATrackingFn(params.environment, AF_COMPLETE_REGISTRATION, {
                customerUserId: userId,
            })

            if (onSuccess) onSuccess()
        } catch (error) {
            const caughtError = error as Error & { message: string; extensions?: { code?: string } }

            let errorMessage = getMessageFromUnknownError(caughtError)

            if (caughtError?.extensions?.code === 'USER_EXISTS') {
                errorMessage = 'An account with this email already exists. Try logging in instead'
            }

            const hasTriggeredCaptchaFlow = params.checkForCaptchaErrorAndTriggerFlow(
                errorMessage,
                () => handleSignup(values, setStatus, setSubmitting, onSuccess),
            )

            if (!hasTriggeredCaptchaFlow) {
                setStatus({ formError: errorMessage })
                setSubmitting(false)
            }

            return
        }
    }

    return handleSignup
}

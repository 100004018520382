import { graphql } from 'react-relay'
import type { Environment } from 'relay-runtime'

import type {
    SignUpWithEmailInput,
    signUpWithEmailMutationResponse,
} from '__generated__/signUpWithEmailMutation.graphql'
import createMutationPromise from 'lib/createMutationPromise'

const signUpWithEmailMutation =
    (relayEnv: Environment) =>
    ({
        email,
        firstName,
        password,
        hasOptedInToMarketing,
        source = 'web-default',
        sessionId,
    }: SignUpWithEmailInput): Promise<signUpWithEmailMutationResponse> => {
        const input = {
            email,
            firstName,
            password,
            hasOptedInToMarketing,
            source,
            sessionId,
        }

        return createMutationPromise<signUpWithEmailMutationResponse>(relayEnv)({
            mutation: graphql`
                mutation signUpWithEmailMutation($input: SignUpWithEmailInput!) {
                    signUpWithEmail(input: $input) {
                        accessToken
                        viewer {
                            me {
                                id
                            }
                        }
                    }
                }
            `,
            variables: { input },
        })
    }

export default signUpWithEmailMutation
